import React from "react";
import "../assets/main.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="privacy-main-content">
        <div className="privacy-heading">
          <h1>Information Security and Data Protection Policy</h1>
        </div>
        <div className="privacy-lms2018">
          <p>
            <b>LMS 2018</b>
          </p>
        </div>
        <h4 className="privacy-introduction">1. Introduction</h4>
        <p className="privacy-subheadings-content-top">
          The purpose of this Information Security and Data Protection Policy
          (“Policy”) is to put in detail how data is handled at List Management
          Services, Inc. (LMS) and the actions that are taken to protect and
          secure all relating software, data, and information. All electronic
          information assets (data) shall be protected and maintained according
          to all applicable federal and state laws.
        </p>
        <div className="privacy-subheadings-content-below">
          <p>
            All data handled at LMS is managed responsibly and gathered to be
            utilized for marketing needs and data analysis.
          </p>
          <p>
            LMS employees and its subsidiaries must follow this Policy; this
            includes contractors, consultants, and other external entities.
          </p>
          <p>
            LMS recognizes the importance of Personal Data and respects the
            privacy rights of individuals. The purpose of this Policy presents
            the principles that are applied to the Processing of Personal Data
            and LMS’s use of Confidential Information. We are committed to
            safeguarding one of the most valuable assets which belongs to our
            consumers and there are procedures installed to ensure such security
            and protection from invasion and breaches.
          </p>
          <p>
            Our Policy dictates how we process and secure the data we obtain,
            highlighting how we protect from and respond to data breaches. This
            is to ensure every level of security is maintained to protect our
            servers and consumers. LMS follows all related requirements and laws
            that deal with data protection and information security to properly
            handle all data and assess possible risks.
          </p>
        </div>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">2. Definitions</h4>
        <p className="privacy-subheadings-content-top">
          All capitalized terms used but not otherwise defined in this Agreement
          shall have the meanings ascribed to such terms as noted below.
        </p>
        <div className="privacy-definations-below">
          <p>
            <b>Controller:</b> Controller means ‘the natural or legal person,
            public authority, agency or other body which, alone or jointly with
            others, determines the purposes and means of the processing of
            personal data; where the purposes and means of such processing are
            determined by Union or Member State law, the Controller or the
            specific criteria for its nomination may be provided for by Union or
            Member State law.’
          </p>
          <p>
            <b>Data Breach:</b> Data Breach refers to a security incident where
            sensitive and personal data may be viewed, stolen, or used by
            someone(s) unauthorized.
          </p>
          <p>
            <b>Data Processor:</b> Data Processor refers to the person or
            organization that processes Personal Data at the behest of the
            Controller(s).
          </p>
          <p>
            <b>Data Protection Officer:</b> Data Protection Officer(s) is a
            designated role assigned to working with the Controller and
            Processor for the protection of personal data.
          </p>
          <p>
            <b>GDPR:</b> General Data Protection Regulation (GDPR) is a
            regulation regarding data privacy and protection within the European
            Union (EU). This EU law determines clear guidelines addressing
            consumer consent guidelines and regulations for international
            business.
          </p>
          <p>
            <b>Joint Controller:</b> Joint Controller is a partner company that
            jointly determines the purposes and means of the processing of
            personal data, alongside the Controller.
          </p>
          <p>
            <b>Personal Data:</b> Personal Data implies data that is relating to
            a living individual who may be identified by the data or from the
            data together with other information in the possession of, or is
            likely to come into possession of LMS.
          </p>
          <p>
            <b>Processing:</b> Processing is the act of retrieving and securing
            the data or acting on a set of operations with the data. This may
            include organizing, adapting, and amending the data as well as
            erasing or destroying the data. Most actions relative to using data
            will fall under the terms of Processing which can be done within a
            mainframe, desktop or laptop, or other similar electronic devices.
          </p>
          <p>
            <b>Processor:</b> Processor is ‘the entity (that can be natural or
            legal person, public authority, agency or other body) which
            processes personal data on behalf of the Controller under the
            Controller’s instructions.’
          </p>
          <p>
            <b>Services:</b> Services refers to the relevant LMS strategies and
            solutions provided relating to data processing, movement, and
            changes.
          </p>
        </div>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">3. Policy</h4>
        <p className="privacy-subheadings-content-top">
          LMS is committed to implementing an information systems database with
          the highest level of security and data protection in order to protect
          and optimize data for various business operations.
        </p>
        <div className="privacy-subheadings-content-below">
          <p>
            The purpose of such security is to provide an appropriate level of
            confidentiality protecting the information, integrity in maintaining
            all data properly, and availability regarding necessary updates,
            required changes, and deliverable output. From passwords to
            firewalls, access is controlled based on service requirements and
            there are procedures in place for gaining and controlling access.
          </p>
          <p>
            Data processing may change depending on which client LMS partners
            with. For more information about process duration, steps, and
            additional details, please see the Master Services Agreement.
          </p>
          <p>
            Regarding concerns over data security and access, formal procedures
            are in place for reporting, investigating, and recording any
            incidents. With the help of the Data Protection Officer(s),
            responsibilities are delegated to avoid communication gaps and to
            help ensure immediate action for all scenarios. The Data Protection
            Officer(s) and other managers may be responsible for ensuring all
            staff/team members are made aware of, and commit to this Policy.
          </p>
          <p>
            This Policy is maintained and governed by laws and regulations
            pertaining to data, freedom of information, computer misuse, and
            more. The intellectual property rights for any software developed on
            LMS equipment by employed staff/team members belong to LMS unless
            explicitly covered by a separate agreement.
          </p>
          <p>
            All unlicensed or unauthorized software will not be permitted on any
            additional LMS equipment. It is forbidden for any user to load or
            operate software found on the Internet or other sources unless
            authorized by LMS and may result in severe civil and criminal
            penalties.
          </p>
        </div>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">4. Consumer Data</h4>
        <p className="privacy-subheadings-content-top">
          LMS both collects and gathers assorted personal and sensitive data
          about consumers. Data is collected for marketing and analysis, which
          is then cataloged for use.
        </p>
        <p className="privacy-subheadings-content-below">
          According to the GDPR regulations of 2018, European consumers now have
          more rights regarding the use of their Personal Data. Upon their
          request, their Personal Data may be returned or deleted from the
          databases that LMS manages.
        </p>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">5. Processors and Controllers</h4>
        <p className="privacy-subheadings-content-top">
          Controllers and Processors shall work together in operating and
          utilizing data while maintaining all legal standards and regulations.
          As data protection requirements adapt and change, responsibilities
          will be distributed as necessary. All parties involved with Personal
          Data will maintain close communication in order to provide awareness
          and updates regarding new protocols, concerns, and adjustments.
        </p>
        <p className="privacy-subheadings-content-below">
          Responsibilities include maintaining data according to lawful
          regulations, ensuring all information is accurate and up-to-date. All
          necessary parties will be informed of potential errors or changes, but
          LMS cannot be held responsible regarding any errors or changes unless
          they have accurately reviewed the information or have been informed of
          them.
        </p>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">6. Data Processing</h4>
        <p className="privacy-subheadings-content-top">
          LMS collects and processes Personal Data. This may include data
          received directly from a data subject regarding correspondence over
          mail, phone, or otherwise, as well as data received from other
          sources, such as business partners.
        </p>
        <p className="privacy-subheadings-content-below">
          Personal Data is only processed for specific purposes such as:
        </p>
        <ol type="A" className="privacy-subheadings-content-below">
          <li>Marketing</li>
          <li>Data Analysis</li>
          <li>Or for any other purposes permitted by global laws</li>
        </ol>
        <div className="privacy-subheadings-content-below">
          <p>
            When the data is collected, we will share the purposes of the data
            to those whose data we utilize. This Policy and our processes will
            be continually reviewed to ensure they reflect our Processing
            intentions and actions. When requested or necessitated, amendments
            will be made to reflect updates and changes.
          </p>
          <p>
            To process Personal Data in a lawful manner, it must be processed
            within legal grounds. This will include consent from the consumer
            for Processing or the ability to prove that Processing is necessary
            with a legal obligation or legitimate interest to collect the
            consumer’s data.
          </p>
          <p>
            When Personal Data is Processing, additional conditions must be
            accounted for and LMS shall ensure such requirements are met when
            it’s necessary for the business.
          </p>
          <p>
            All data processing shall comply with lawful regulations, making
            changes as necessary to ensure the security and proper maintenance
            of sensitive and personal data. The manner in which data is
            processed and handled by Controllers will be dependent on the
            client.
          </p>
          <p>The LMS unsubscribe process is as follows:</p>
        </div>
        <ol type="A" className="privacy-subheadings-content-below">
          <li>Insert an unsubscribe time stamp</li>
          <li>
            Migrate email, date added, unsubscribe date, campaign, and rep
            contact flag into an “Unsubscribe” table
          </li>
          <li>Repeat for each individual requested product</li>
        </ol>
        <p className="privacy-subheadings-content-below">
          LMS has appointed Data Protection Officer(s) that will provide all
          data on any specific individual upon request: Felix Torres.
        </p>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">7. Key Requirements</h4>
        <p className="privacy-subheadings-content-top">
          In order to comply with all legal regulations, Personal Data will:
        </p>
        <ol type="A" className="privacy-subheadings-content-below">
          <li>
            Be retrieved and processed fairly and lawfully only when all proper
            conditions are met
          </li>
          <li>
            Be obtained for lawful specific purposes and will not be processed
            for any other reasons
          </li>
          <li>Be accurate, relevant, and updated appropriately</li>
          <li>Not be retained longer than necessary for specific purposes</li>
          <li>
            Be retained securely and away from unauthorized access, loss, or
            destruction
          </li>
        </ol>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">8. Protection and Security</h4>
        <p className="privacy-subheadings-content-top">
          The Data Protection Act of 1998 described how organizations must
          collect, manage, and store Personal Data. The GDPR of 2018 prepared
          regulations of how Personal Data could be retrieved, deleted, managed,
          to support consumers and the information gathered from them. These are
          two of the lawful regulations that are followed by LMS. To comply with
          these laws, Personal Data must be fairly collected and used, stored
          safely, and not unlawfully disclosed. Several steps are taken to
          protect and secure all data used by LMS.
        </p>
        <p className="privacy-subheadings-content-below">
          This includes data encryption and security with encryption in transit.
          Security actions include:
        </p>
        <ol type="A" className="privacy-subheadings-content-below">
          <li>
            Utilizing encryption capabilities including EBS, S3, and Redshift
          </li>
          <li>Encrypting in transit with TLS across all necessary services</li>
          <li>Maintaining flexible key management options to handle control</li>
          <li>Encrypting message queues for transmitting sensitive data</li>
        </ol>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">9. Data Breaches</h4>
        <p className="privacy-subheadings-content-top">
          On the chance of a Data Breach regarding Personal Data, there is a
          potential risk to the freedoms and rights of the consumers whose data
          was managed. Such breaches are taken seriously and in order to
          mitigate immediate risks of any kind of damage, immediate action shall
          be taken.
        </p>
        <div className="privacy-subheadings-content-below">
          <p>
            LMS will take all possible measures to eliminate risks of data
            breaches and establish steps in the case of a breach happening.
          </p>
          <p>
            Addressing international compliance standards, all consumers and
            partners will be notified within 72 hours of a breach and will
            document all relating facts to provide awareness for LMS. Once the
            notifications have been sent, the Data Protection Officer will
            consider the breaches so LMS can prepare to implement appropriate
            measures against continual or similar Data Breaches.
          </p>
        </div>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">10. Risk Management</h4>
        <p className="privacy-subheadings-content-top">
          In order to protect against Data Breaches and in preparing to recover
          quickly if they occur, LMS will be prepared with reliable backup
          systems. With guidance from the Data Protection Officer(s), the teams
          will be prepared to manage virus protection, disaster recovery, and
          any other typical concerns regarding Data Breaches.
        </p>
        <p className="privacy-subheadings-content-below">
          By preparing for risk management before any errors or breaches are
          made, LMS will be able to move quickly and assuredly to close gaps or
          similar issues so that Personal Data may not be or continue to be,
          stolen, manipulated, or used unlawfully.
        </p>
        <p>&nbsp;</p>
        <h4 className="privacy-subheadings">11. Amendments</h4>
        <p className="privacy-subheadings-content-top">
          This LMS Policy will be updated in a timely manner addressing any
          necessary or requested changes for our methods and practices,
          including new legal regulations. It will be updated by the Data
          Protection Officer(s) and may be requested for reference at any time.
        </p>
        <p>&nbsp;</p>
        <p className="privacy-subheadings-content-below">
          Date of issue: May 2018
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
